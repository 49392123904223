<template>
	<div class="more">
		<div class="content">
			<div class="center">
				<div
					class="center-item"
					v-for="(item, index) in moreList"
					:key="index"
				>
					<div class="center-item-img">
						<img
							v-lazy="item.src"
							:key="item.src"
							:alt="item.title"
						/>
					</div>
					<div class="center-item-text">
						<h3>{{ item.title }}</h3>
						<p v-for="(i, index) in item.text" :key="index">
							{{ i }}
						</p>
					</div>
				</div>
				<div class="center-code">
					<div class="center-code-img">
						<img v-lazy="codeImg" :key="codeImg" alt="小程序码" />
					</div>
					<div class="center-code-text">
						<h3>关注"慧听智能语音演示系统"</h3>
						<p>· 体验更多公司产品</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'more',
	data() {
		return {
			codeImg: require('@/assets/img/xcx.png'),
			moreList: [
				{
					src: require('@/assets/img/experience/more1.png'),
					title: '自然语言理解NLP',
					text: [
						'· 支持场景对话和知识问答',

						'· 语义理解能力强、知识表述能力强',

						'· 支持语义分析与相关问题联想',

						'· 支持多种领域意图分析',
					],
				},
				{
					src: require('@/assets/img/experience/more2.png'),
					title: '声纹识别VPR',
					text: [
						'· 支持“一对一确认”与“多选一辨别”',

						'· 先进的模式匹配识别模型',

						'· 文本无关识别、文本相关识别',

						'· 数字串识别',
					],
				},
				{
					src: require('@/assets/img/experience/more3.png'),
					title: '麦克风阵列SMA',
					text: [
						'· 增强说话方向语音，抑制其他噪音',

						'· 独有回声消除技术，让识别更精准',

						'· 有效辨别环境杂音，提高识别率',

						'· 有效识别距离可达3-5米',
					],
				},
			],
		}
	},
	watch: {},
	methods: {
		getlocal() {
			//找到锚点id
			let toElement = document.getElementById('experienceId')
			toElement.scrollIntoView()
		},
	},
	components: {},
	computed: {},
	created() {
		//创建时执行跳转锚点位置
		this.$nextTick(() => {
			this.getlocal()
		})
	},
	mounted() {},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.more {
	// scroll-padding-top: 50vh;
	.content {
		width: 100%;
		.center {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 0.4rem;
			grid-auto-flow: row;
			justify-content: space-around;

			.center-item {
				width: 5rem;
				height: 4.1rem;
				display: flex;
				justify-content: space-around;
				align-items: center;
				background-color: #f8fbff;
				padding: 0 0.6rem;
				.center-item-img {
					width: 1.4rem;
					height: 1.4rem;
				}
				.center-item-text {
					text-align: left;
					text-indent: 0.4rem;
					h3 {
						font-size: 0.2rem;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 700;
						line-height: 1;
						color: #333333;
						margin-bottom: 0.24rem;
					}
					p {
						font-size: 0.18rem;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 400;
						line-height: 0.32rem;
						color: #666666;
					}
				}
			}
			.center-code {
				width: 5rem;
				height: 4.1rem;
				display: flex;
				justify-content: space-around;
				align-items: center;
				background-color: #f8fbff;
				padding: 0 0.6rem;
				.center-code-img {
					width: 1.4rem;
					height: 1.4rem;
				}
				.center-code-text {
					h3 {
						font-size: 0.2rem;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 700;
						line-height: 1;
						color: #333333;
						margin-bottom: 0.24rem;
					}
					p {
						font-size: 0.18rem;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 400;
						line-height: 1;
						color: #666666;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1200px) {
}
</style>
